.form {
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 400px;
}

@media screen and (max-width: 1024px) {
  .form {
    width: 100%;
    margin-bottom: 0;
  }

  .protocolBtn {
    font-size: 12px!important;
  }
}
