.capycha-action {
  display: block;
  cursor: pointer;
  border-radius: 2px;
  padding: 10px;
  background: rgba(255, 165, 0, 0.1);
  color: #ff8d00;
  &:active, &:hover{
    color: #ff8d00;
  }
}

:global .as-pd10{
  position: relative;
  padding: 15px 0 0 15px;
}
