.wrapper {
  position: relative;

  .title {
    height: 36px;
    line-height: 36px;
    padding-left: 16px;
    background-color: rgba(24, 144, 255, 0.1);
    border-left: 3px solid rgb(24, 144, 255);
    margin-bottom: 20px;
  }
}
