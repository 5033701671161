.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
  min-height: calc(100vh - 80px - 22px - 22px - 140px);
  // height: 100vh;
  overflow: auto;
}

@media screen and (max-width: 1024px) {
  .wrap {
    width: 100%;
    min-height: calc(100vh - 80px - 22px - 22px - 211px);
  }
}
