.btn {
  position: absolute;
  top: 0;
  right: 0;
}

.content {
  width: 100%;
  height: 90vh;
  iframe {
    border: 0;
  }
}
.modal {
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-close-x {
      color: #fff;
    }
  }
}