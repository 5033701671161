.content {
  height: 600px;
  overflow: auto;
}

.title {
  text-align: center;
  font-size: 18px;
}

@media screen and (max-width: 1024px) {
  .content {
    height: calc(100vh - 156px);
  }

  .modalWrapper {
    overflow: hidden !important;
  }

}