.wrap {
  width: 100%;

  font-size: 15px;
  font-weight: 600;
  color: #333333;
  line-height: 33px;
  text-align: center;
}

.logo {
  width: 170px;
  height: 56px;
  display: block;
  margin: 20px auto 16px;
  background: url('~@/assets/images/rizhao/rizhao_logo.png');
}

.markStyle {
  width: 64px;
  height: 12px;
  display: inline-block;
  background: url('~@/assets/images/rizhao/rizhao_title_mark.png');
}

.titleBox {
  margin-top: 40px;
  margin-bottom: 40px;

  .leftMark {
    .markStyle;
    margin-right: 12px;
  }

  .rightMark {
    .markStyle;
    margin-left: 12px;
    transform: rotate(180deg);
  }
}

.border {
  height: 1px;
  background: #EEEEEE;
}

@media screen and (max-width: 1024px) {
  .titleBox {
    margin-bottom: 34px;
  }
}
