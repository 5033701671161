.actionBtn {
  margin-top: 34px;
}

.formActionWrapper {
  width: 100%;
}

@keyframes loading_rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg)
  }
}

.stateText{
  text-align: center;
  margin-bottom: 20px;
  .loading{
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: -1px;
    animation: loading_rotate 2s linear infinite;
    transform: rotate(45deg);
  }
  span{
    font-size: 16px;
  }
}
.tipText{
  text-align: center;
  color: #999999;
}

// .downloadPlugText {
//   display: flex;
//   align-items: center;
//   margin-bottom: 20px;

//   font-size: 14px;
//   color: #666666;
//   line-height: 19px;

//   .downloadPlugTextIcon {
//     margin-left: 10px;
//     width: 18px;
//     height: 18px;

//     background-repeat: no-repeat;
//     background-size: cover;
//     background-image: url('~@/assets/images/downloadPlug/icon.png');
//     cursor: pointer;
//   }
// }
