.loadWrap{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg{
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerWrap{
  position: absolute;
  top: 0;
  width: 100%;
}
.formWrap{
  width: 520px;
  // margin: auto;
  padding: 60px 90px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 29px 4px rgba(0, 122, 255, 0.1);
  .title{
    margin-bottom: 50px;
    font-size: 36px;
    color: #333;
    text-align: center;
  }
  .checkText{
    user-select: none;
  }
  .des{
    margin-top: 30px;
    font-size: 14px;
    color: #999;
    .dest{
      margin-bottom: 6px;
    }
    .step{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .downBtnWrap{
      text-align: right;
      user-select: none;
      img{
        vertical-align: baseline;
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .formWrap {
    width: 90%;
    padding: 40px;
    .title{
      margin-bottom: 20px;
      font-size: 32px;
    }
  }
}