.wrap {
  background: #fff;
  height: calc(100vh - 80px);
  margin-top: 40px;
  padding: 4px 0;
  .container {
    margin-top: 120px;
    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 340px;
        cursor: pointer;
        margin: 0 60px;
      }
      .selectedLeft {
        box-shadow: 0 0 20px #54eaff;
      }
      .selectedRight {
        box-shadow: 0 0 20px #ffd890;
      }
    }
    .btnBox {
      display: flex;
      justify-content: center;
      margin-top: 150px;
      .btn {
        width: 240px;
        height: 50px;
      }
    }
  }
}
