.wrap {
  background: #fff;
  
  width: 410px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .wrap {
    width: 335px;
    padding-left: 25px;
    padding-right: 25px;
  }
}