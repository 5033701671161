.index {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &[data-include=false] {
    justify-content: center;
  }
  :global {
    .ant-btn-link {
      padding: 4px 0;
    }
  }
}

.row {
  display: flex;
  align-items: center;
}

.btn {
  margin-right: 10px;
}

.icon {
  color: #CACED0;
}