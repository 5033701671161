.spinBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
}

.alert {
  margin-bottom: 140px;

  text-align: center;
}
