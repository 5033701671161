.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  height: 200px;

  text-align: center;
}

.title {
  margin-bottom: 13px;

  font-size: 16px;
  color: #333333;
  line-height: 21px;
}

.description {
  font-size: 14px;
  color: #666666;
  line-height: 19px;
}

.btn {
  margin-top: 24px;

  :global {
    .ant-btn {
      width: 140px;
    }
  }
}
