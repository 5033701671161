.box {
  text-align: center;
  // min-height: 100vh;
  background-color: #FFFFFF;
  padding: 25px;
  color: #333;
  font-size: 12px;
}
.icon {
  width: 142px;
  height: 107px;
  margin-top: 55px;
  margin-bottom: 24px;
}
.link {
  color: #666;
  margin-top: 7px;
  word-break: break-all;
}
.btn {
  width: 275px;
  height: 40px;
  background: #55ACF6;
  font-size: 14px;
  color: #FFF;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 32px;
  box-shadow: none;
  border: 1px solid #55ACF6;
}