.wrap {
  margin-bottom: 22px;
  background-color: #fff;
}

.box {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
  height: 80px;
}

.imgTip{
  position: absolute;
  top: 70px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  max-width: 80vw;
  z-index: 2;
  img {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
    max-width: 100%;
  }
}

.logo {
  width: 371px;
  height: 51px;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 1024px) {
  .box {
    width: 100%;
    height: 50px;
  }

  .logo {
    margin-left: 16px;
    height: 27px;
    background-size: contain;
  }
}
