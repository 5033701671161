.form {
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 400px;
}

.alert {
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 400px;
}

.btn {
  margin-top: 34px;
}

.reload {
  display: flex;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 15xp;
}

.protocolBtn {
  padding: 0!important;
}

@media screen and (max-width: 1024px) {
  .form {
    width: 100%;
    margin-bottom: 20px;
  }

  .protocolBtn {
    font-size: 12px!important;
  }

  .alert {
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
  }
}

.downloadPlugTextTip{
  display: inline-block;
  margin-bottom: 15px;
}

.downloadPlugText {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  font-size: 14px;
  color: #666666;
  line-height: 19px;

  .downloadPlugTextIcon {
    margin-left: 10px;
    width: 18px;
    height: 18px;

    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('~@/assets/images/downloadPlug/icon.png');
    cursor: pointer;
  }
}

.plugConfirmation {
  text-align: center;
}

.plugConfirmationTitle {
  margin-top: 56px;
  margin-bottom: 13px;

  font-size: 16px;
  color: #333333;
  line-height: 21px;
}

.plugConfirmationDescription {
  margin-bottom: 56px;

  font-size: 14px;
  color: #666666;
  line-height: 19px;
}

.startPlugConfirmation {
  text-align: center;

  .title {
    padding-top: 20px;
    font-size: 16px;
    color: #333333;
    line-height: 21px;
  }

  .content {
    position: relative;
    padding: 20px 0 20px;
  }

  .actionWrapper {
    padding-top: 20px;
  }

  .actionButton {
    width: 180px;
  }
}

.unSelected {
  user-select: none;
}

.highlight {
  font-weight: bold;
}

.link {
  cursor: pointer;
  color: #1890ff;
  text-decoration: underline;
}

.plugConfirmationFooter {
  display: flex;
  justify-content: center;

  :global {
    .ant-btn {
      width: 140px;
    }
  }
}
