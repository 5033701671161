.count-down-modal {
  :global {
    .ant-modal-confirm-btns {
      display: none;
    }
  }
  .count-down {
    text-align: center;
    color: #faad14;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 700;
  &>span {
    margin-right: 16px;
    font-size: 22px;
    color: #52c41a;
    margin-left: -15px;
  }
}


.center {
  text-align: center;
  margin-top: 20px;
}