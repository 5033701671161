.btn {
  display: flex;
  align-items: center;
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}

.captchInput {
  :global {
    .ant-input-group-addon {
      padding: 0;
    }
    button {
      border: 0;
      border-radius: 0;
      height: 100%;
    }
  }
}
