.container {
  .alert {
    margin: 24px auto;
    width: 560px;
  }
  .suspension {
    position: fixed;
    top: 25%;
    right: 21%;
    img {
      width: 220px;
      cursor: pointer;
    }
  }
  .content {
    margin: auto;
    margin-left: calc(50% - 280px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
