.form {
  width: 560px;
  .downloadBtn {
    width: 100%;
    border: 0;
    color: #55acf6;
    background: #eef6fe;
    margin-bottom: 30px;
  }
  .submit {
    width: 100%;
  }
  .code {
    color: #666;
    margin: -14px 0 15px 11px;
    font-size: 12px;
  }
  :global {
    .ant-input-disabled {
      background: #fcfcfc;
      color: #666;
      height: 40px;
    }
    .ant-col {
      max-width: 100%;
    }
    .ant-cascader-input.ant-input {
      height: 40px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
      padding: 4px 11px;
    }
  }
}
