.wrap {
  width: 100%;

  font-size: 24px;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
  text-align: center;
}

.btn {
  margin-top: 40px;
}

em {
  color: #FF3535;
  font-style: normal;
}
