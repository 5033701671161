.wrap {
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  min-height: 100vh;
  // background: #ffffff;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(238, 238, 238, 0.5);

  &::before, &::after {
    display: table;
    line-height: 1;
    content: '';
  }

  &::after {
    clear: both;
  }
}

@media screen and (max-width: 1024px) {
  .wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}
