.wrap {
  width: 100%;

  font-size: 24px;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
  text-align: center;
}

.title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.border {
  height: 1px;
  background: #EEEEEE;
}
