.authBtn {
  margin-top: 34px;
}

.rzBtn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
