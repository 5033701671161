.formWrap{
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  width: 400px;
}

@media screen and (max-width: 1024px) {
  .formWrap {
    width: 100%;
  }
}
