.content{
  width: 100%;
  margin-top: 50xp;
}
.imgWrap{
  width: 100%;
  margin-bottom: 30px;
  // border: 1px solid #ccc;
  text-align: center;
  img{
    width: 250px;
    height: 250px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}
.desc{
  text-align: center;
}
