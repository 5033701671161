.content{
  margin-top: 24px;
}

.qrcodeWrap{
  margin: 30px 0;
  text-align: center;
  .tip{
    margin-bottom: 30px;
  }
  .codeWrap{
    width: 200px;
    height: 200px;
    margin: auto;
    padding: 10px;
    border: 1px solid #e1e1e1;
    box-shadow: 0 0 10px #ccc;
    .qrcodeImg{
      width: 100%;
      height: 100%;
      background-size: 100%;
      user-select: none;
    }
  }
}

.loadingWrap{
  margin-top: 45px;
  font-size: 48px;
  color: #1890ff;
}

.reload {
  margin-top: 10;
  font-size: 15px;
}