.content {
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  width: 400px;
}

.main{
  margin: 20px auto;
  .img{
    display: block;
    width: 400px;
    margin: 0 auto 20px;
  }
  .subTitle{
    text-align: center;
    color: #333;
    font-size: 17px;
    font-weight: 500;
  }
  .tips{
    margin-top: 20px;
    text-align: center;
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    line-height: 2em;
  }
  .step{
    margin-top: 20px;
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    padding: 0 20px;
    ol{
      line-height: 2em;
    }
  }
  .btnWrap{
    margin-top: 40px;
    text-align: center;
    button{
      display: block;
      width: 300px;
      margin: 0 auto 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .content {
    width: 100%;
    margin-bottom: 0;
  }

  .main{
    .img{
      display: block;
      width: 100%;
      margin: 0 auto 20px;
    }
  }

  .protocolBtn {
    font-size: 12px!important;
  }
}
