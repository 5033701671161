.wrap {
  margin-bottom: 140px;
  padding-top: 94px;

  text-align: center;
  color: #333;
  font-size: 14px;
  img {
    width: 142px;
    height: 108px;
    margin-bottom: 40px;
  }
}
.tip {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
}
