.fileInput {
  margin-bottom: 20px;
  :global {
    .ant-input-group-addon {
      background-color: #fff;
    }
    .ant-input-disabled {
      background-color: #fff;
      height: 40px;
    }
    .ant-upload {
      width: 100%;
    }
  }
}
.fileInput:last-child {
  margin-bottom: 0;
}
