
.unSelected {
  user-select: none;
}

.protocolBtn {
  padding: 0!important;
}

.modalContent{
  max-height: 70vh;
  overflow: auto;
}