@imageWidth: 302px;
@imageHeight: 220px;

.verifyPanel{
  position: relative;
  width: 100%;
  height: 100%;
  .pointTag{
    position: absolute;
    width: 28px;
    height: 28px;
    text-align: center;
    border-radius: 14px;
    line-height: 28px;
    font-size: 12px;
    cursor: default;
    background: #1890ff;
    color: #fff;
  }
  .loadingMask{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    color: #999;
  }
}
.verifyImage{
  width: 100%;
  height: 100%;
  // img{
  //   width: 100%;
  //   height: 100%;
  // }
}
.verifyImageLoading{
  width: @imageWidth;
  height: @imageHeight;
  line-height: @imageHeight;
  text-align: center;
  background: #efefef;
}

.btnWrap{
  text-align: right;
  margin-bottom: 10px;
  .refreshDisabled{
    margin-right: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #acacac;
  }
  a{
    margin-right: 24px;
    font-size: 14px;
    font-weight: 400;
  }
}