.wrap {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 160px;
  padding-bottom: 160px;
  width: 100%;
  min-height: 100vh;
  background: url('~@/assets/images/rizhao/rizhao_pc_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.containerStyle {
  margin: 0 auto;
  background: #ffffff;
  border-radius: 10px;
  &::before, &::after {
    display: table;
    line-height: 1;
    content: '';
  }

  &::after {
    clear: both;
  }
}

.container {
  width: 760px;
  .containerStyle;
}

@media screen and (max-width: 1024px) {
  .wrap {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    background: url('~@/assets/images/rizhao/rizhao_mobile_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .container {
      width: 100%;
      .containerStyle;
    }
  }
}
